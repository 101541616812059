<template>
  <div class="exam-wrapper p-3 h-screen" style="background: #f2f2f6;">
    <!-- mobile -->
    <el-drawer
      :visible.sync="mobileSide"
      direction="ltr"
      size="50%"
      :show-close="false"
    >
      <div class="px-3">
        <el-divider class="word-keep-all">试卷信息</el-divider>
        <p class="mb-2 text text-left">{{ exam.name }}</p>
        <div class="flex flex-wrap justify-between">
          <span>总分：{{ exam.score }}</span>
          <span>时长：{{ exam.suggest_time }}（分）</span>
        </div>
        <el-divider class="word-keep-all">考生信息</el-divider>
        <div class="flex flex-wrap justify-around items-center">
          <el-avatar class="my-2" :src="$store.getters.avatar" :size="80"></el-avatar>
          <div class="my-2 p-3" style="border: 1px dashed #ccc;">
            <div class="flex justify-between pb-3 mb-3 word-keep-all" style="border-bottom: 1px dashed #ccc;">
              <span>用户：</span>
              <span>{{ $store.getters.name }}</span>
            </div>
            <div class="flex justify-between word-keep-all">
              <span>账号：</span>
              <span>{{ $store.getters.username }}</span>
            </div>
          </div>
        </div>
        <el-divider class="word-keep-all">题目内容</el-divider>
        <div class="mt-4">
          <div v-if="exam.section.length > 0">
            <div v-for="(secItem, secIndex) of exam.section" :key="secIndex">
              <p class="mb-2">{{ secItem.title }}</p>
              <div v-if="secItem.questions.length > 0" class="flex flex-wrap">
                <el-button
                  class="mr-1 mb-2"
                  v-for="(quesItem, quesIndex) of secItem.questions" :key="quesIndex"
                  size="small"
                  :type="quesItem.is_correct ? 'success': 'danger'"
                  circle
                  @click="locateQuestion(secIndex, quesIndex)"
                >
                  {{ quesItem.sort.toString().padStart(2, '0') }}
                </el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="flex mt-4">
          <el-button class="w-full mb-2" size="small" icon="el-icon-close" @click="cancelExam">退出解析</el-button>
        </div>
      </div>
    </el-drawer>
    <!-- 右侧浮动倒计时 -->
    <div
      class="lg:hidden mobile-btn px-2 py-1"
      style="border-radius: 4px 0 0 4px; background: #fefefe; z-index:10;"
    >
      <el-button class="mt-1" size="mini" @click="mobileSide = true">试卷选项</el-button>
    </div>
    <!-- end mobile -->
    <div class="flex">
      <div class="left w-1/5 lg:block hidden p-4 rounded mr-5 overflow-y-auto" style="height: 95vh;background: #fefefe;">
        <el-button class="w-full mb-2" size="small" icon="el-icon-close" @click="cancelExam">退出解析</el-button>
        <el-divider class="word-keep-all">试卷信息</el-divider>
        <p class="mb-2 text text-left">{{ exam.name }}</p>
        <div class="flex flex-wrap justify-between">
          <span>总分：{{ exam.score }}</span>
          <span>时长：{{ exam.suggest_time }}（分）</span>
        </div>
        <el-divider class="word-keep-all">考生信息</el-divider>
        <div class="flex flex-wrap justify-around items-center">
          <el-avatar class="my-2" :src="$store.getters.avatar" :size="80"></el-avatar>
          <div class="my-2 p-3" style="border: 1px dashed #ccc;">
            <div class="flex justify-between pb-3 mb-3 word-keep-all" style="border-bottom: 1px dashed #ccc;">
              <span>用户：</span>
              <span>{{ $store.getters.name }}</span>
            </div>
            <div class="flex justify-between word-keep-all">
              <span>账号：</span>
              <span>{{ $store.getters.username }}</span>
            </div>
          </div>
        </div>
        <el-divider class="word-keep-all">题目内容</el-divider>
        <div class="mt-4">
          <div v-if="exam.section.length > 0">
            <div v-for="(secItem, secIndex) of exam.section" :key="secIndex">
              <p class="mb-2">{{ secItem.title }}</p>
              <div v-if="secItem.questions.length > 0" class="flex flex-wrap">
                <el-button
                  class="mr-1 mb-2"
                  v-for="(quesItem, quesIndex) of secItem.questions" :key="quesIndex"
                  size="small"
                  :type="quesItem.is_correct ? 'success': 'danger'"
                  circle
                  @click="locateQuestion(secIndex, quesIndex)"
                >
                  {{ quesItem.sort.toString().padStart(2, '0') }}
                </el-button>
              </div>
            </div>
          </div>
        </div>
        
        
      </div>
      
      <div class="right lg:w-4/5 w-full p-4 overflow-y-auto rounded" style="height: 95vh; background: #fefefe;" ref="content">
        <div v-if="exam.section.length > 0">
          <div v-for="(secItem, secIndex) of exam.section" :key="secIndex">
            <p class="bg-gray-200 mb-2 py-3 rounded text-xl text-gray-700">{{ secItem.title }}</p>
            <div v-if="secItem.questions.length > 0">
              <div class="mb-3" :data-id="`quesBox${secIndex}quesId${quesIndex}`" :ref="`quesBox${secIndex}`" v-for="(quesItem, quesIndex) of secItem.questions" :key="quesIndex">
                <div class="flex text-left text-lg">
                  <span>{{ quesItem.sort }}. </span>
                  <div>
                    <div v-html="quesItem.title" class="mb-2"></div>
                    <!-- 选择题、判断题 -->
                    <div v-if="[0, 1].includes(quesItem.question_type) && quesItem.items.length > 0">
                      <el-radio-group v-model="quesItem.answer" disabled class="flex flex-col">
                        <el-radio
                          v-for="(item, itemIndex) of quesItem.items"
                          :key="itemIndex"
                          :label="item.prefix"
                          class="mb-2"
                        >
                          <div class="flex" style="font-size: 18px;">
                            {{ item.prefix }}.
                            <div v-html="item.content"></div>
                          </div>
                        </el-radio>
                      </el-radio-group>
                    </div>
                    <!-- 多选题 -->
                    <div v-else-if="quesItem.question_type === 2 && quesItem.items.length > 0">
                      <el-checkbox-group
                        disabled
                        v-model="quesItem.answer_array"
                      >
                        <el-checkbox
                          v-for="(item, itemIndex) of quesItem.items"
                          :key="itemIndex"
                          :label="item.prefix"
                        >
                          <div class="flex" style="font-size: 18px;">
                            <span>{{ item.prefix }}. </span>
                            <div v-html="item.content"></div>
                          </div>
                        </el-checkbox>
                      </el-checkbox-group>
                    </div>
                    <!-- 求解题 -->
                    <div v-else-if="quesItem.question_type === 3 || quesItem.question_type === 5">
                      <div class="flex items-center mb-2" v-for="enter in quesItem.enter_num" :key="enter">
                        <span>（{{ enter }}）</span>
                        <el-input v-model="quesItem.answer_array[enter-1]"></el-input>
                      </div>
                    </div>
                    <!-- 图形化编程题 -->
                    <div v-else-if="quesItem.question_type === 4 && quesItem.type === 0">
                      <el-button type="primary" icon="el-icon-view" @click="openScratch(quesItem.project_id)">查看代码</el-button>
                      <p class="my-2 px-3 py-2 bg-yellow-200 text-yellow-500 rounded" style="font-size: 16px;"><el-icon class="el-icon-info"></el-icon> 编程题暂不做评分，只提供做题思路，请联系指导教师进行订正</p>
                    </div>
                    <!-- C++ 或 Python -->
                    <div v-else-if="quesItem.question_type === 4 && [1,2].includes(quesItem.type)">
                      <span>填写代码：</span>
                      <editor :answer="quesItem.answer" :type="quesItem.type" @update-answer="updateAnswer($event, quesItem)"/>
                      <p class="my-2 px-3 py-2 bg-yellow-200 text-yellow-500 rounded" style="font-size: 16px;"><el-icon class="el-icon-info"></el-icon> 编程题暂不做评分，只提供做题思路，请联系指导教师进行订正</p> 
                    </div>
                    <div class="mt-2 p-2" style="font-size: 16px; border: 1px dashed #ccc;">
                      <div class="flex flex-wrap">
                        <span>结果：</span>
                        <div>
                          <el-tag type="success" size="mini" v-if="quesItem.is_correct" style="vertical-align: text-top;">正确</el-tag>
                          <el-tag type="danger" size="mini" v-else style="vertical-align: text-top;">错误</el-tag>
                        </div>
                      </div>
                      <div class="flex flex-wrap">
                        <span>得分：</span>
                        <div> {{ quesItem.is_correct ? quesItem.score: 0 }} / {{ quesItem.score }}</div>
                      </div>
                      <div class="flex flex-wrap items-center">
                        <span>难度：</span>
                        <el-rate v-model="quesItem.difficult" disabled></el-rate>
                      </div>
                      <div class="flex flex-wrap">
                        <span class="whitespace-nowrap">标准答案：</span>
                        <div class="text-gray-500 whitespace-pre" v-html="quesItem.correct"></div>
                      </div>
                      <div class="flex">
                        <span class="whitespace-nowrap">答案解析：</span>
                        <div v-if="quesItem.analyze" v-html="quesItem.analyze"></div>
                        <div v-else>暂无解析</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import settings from '@/setting'
import { analysis } from '@/api/exam'

import Editor from './componentes/editor.vue'

export default {
  name: 'ExamAnalysis',
  data() {
    return {
      exam: {
        id: 0,
        name: '',
        section: [],
        score: 0,
        suggest_time: 0,
        level: 0,
        user: {
          exam_id: 0
        }
      },
      mobileSide: false
    }
  },
  components: {
    Editor,
  },
  mounted() {
    this.exam.user.id = this.$route.query.id
    this.init()
  },
  methods: {
    init() {
      const loading = this.$loading({
        lock: true,
        text: '试卷加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
      });
      analysis({ id: this.exam.user.id }).then(res => {
        this.exam = res.data
        loading.close();
      })
    },
    locateQuestion(secIndex, quesIndex) {
      let px = this.$refs[`quesBox${secIndex}`][quesIndex].offsetTop + 1
      this.$nextTick(() => {
        this.$refs.content.scrollTo(0, px - 20)
      })
    },
    // 取消答题
    cancelExam() {
      this.$confirm('你确定退出答案解析吗？').then(() => {
        this.sidebarStatus = false
        window.close()
      }).catch(() => {
        console.log('exit exam error')
      })
    },
  
    openScratch(id) {
      const href = `${settings.scratchUrl}?edit#${id}`
      window.open(href, 'scratch')
    },
    updateAnswer(e, quesItem) {
      quesItem.answer = e
    },
  }
}
</script>

<style scoped>
.el-button+.el-button {
  margin-left: 0
}
.mobile-btn {
  position: fixed;
  top: 50%;
  right: 0;
}
.word-keep-all {
  word-break: keep-all;
}
.el-radio {
  display: flex;
}
</style>